@import "src/custom-component-lib/assets/custom-tailwind-css";

$nav-height: 64px;
$footer-height: 100px;
$background-color: #212121; // #001532;
$background2-color: #212121CC;
$background3-color: #21212100;

.nav-bar {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: $nav-height;

  @apply bg-blue-400;

  .nav-items {
    @apply mx-4 h-full flex-1 flex items-center justify-between;

    .title-items {
      @apply flex;

      span {
        @apply text-white ml-3;
      }

      .logo {
        width: 36px;
        @apply m-1 rounded-md overflow-hidden;

        img {
          height: 34px;
        }
      }

      .title {
        @apply flex items-center;
      }
    }

    .functions-items {
      @apply grid grid-cols-4 justify-items-end;
      height: 34px;
      width: 240px;

      img {
        height: 34px;
      }
    }

    .one-right-btn {
      @apply grid grid-cols-1 justify-items-end;
      width: 60px;

      img {
        height: 34px;
      }
    }
  }
}

.modal-title {
  @apply text-blue-500;
}

.modal-body {
  @apply text-[14px];
}

.modal-footer {
  @apply flex justify-center;

  .button-size {
    width: 45%;
  }
}

.btn-details {
  @apply flex flex-wrap justify-center mt-5 sm:mt-8 mb-4;

  .btn-save {
    @apply w-[120px] sm:w-[150px];
  }

  .btn-cancel {
    //width: 150px;
    @apply w-[120px] sm:w-[150px] ml-10 sm:ml-4;
  }

  .btn-other {
    //width: 150px;
    @apply w-[280px] sm:w-[150px] mt-5 sm:ml-4 sm:mt-0;
  }

  .btn-full {
    @apply w-[315px];
  }
}

.btn-remove-details {
  @apply flex justify-center pt-3 mb-2;

  .btn-remove {
    width: 250px;
    height: 50px;
  }
}

.btn-bg-color {
  background-color: $background-color!important;
}

.button-group {
  @apply relative inline-flex items-center border border-gray-300 bg-white;
  @apply px-2 py-2 text-sm font-medium text-gray-500;
  @apply hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-opacity-100;

  &.opaque {
    @apply bg-opacity-60;
  }

  &.left {
    @apply rounded-l-md;
  }

  &.center {
    @apply -ml-px;
  }

  &.right {
    @apply -ml-px rounded-r-md;
  }
}

.hide-mobile {
  @apply hidden md:block;
}

.show-mobile {
  @apply block md:hidden;
}

.back-header-container {
  @apply pt-2 mb-2 sm:pt-3 sm:mb-5;

  .text-custom {
    @apply ml-1 text-xs sm:ml-2 sm:text-base font-normal;

    &:hover {
      @apply text-blue-500;
    }

    &.small {
      @apply sm:text-sm;
    }
  }
}

.add-new-container {
  @apply grid grid-cols-1 sm:grid-cols-6 mt-6 mb-4;

  div {
    @apply sm:col-start-3 sm:col-span-2;
  }
}

.custom-pagination ::ng-deep .ngx-pagination li{
  @apply rounded border border-0;
}

.custom-pagination ::ng-deep .ngx-pagination a{
  @apply rounded border border-0 hover:bg-blue-200;
}

.structure-name {
  @apply text-base md:text-lg lg:text-xl font-bold;
}

.structure-city {
  @apply text-xs md:text-base lg:text-lg text-gray-500;
}

.container-full-screen {
  @apply mx-3 sm:mx-5 mt-3 md:mt-6 lg:mt-10;
}
