//@import "ng-multiselect-dropdown.theme";

.btn {
  @apply border px-4 py-2 rounded-lg font-medium focus:outline-none focus:ring;
}

.btn-disabled {
  @apply opacity-50 cursor-not-allowed bg-gray-200 text-gray-500 border-gray-200;
}

.btn-gray {
  @apply transition text-white bg-gray-500 hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300 border-gray-500
}

.btn-red {
  @apply transition text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300 border-red-500
}

.btn-yellow {
  @apply transition text-black bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-300 border-yellow-500
}

.btn-green {
  @apply transition text-white bg-green-500 hover:bg-green-600 active:bg-green-700 focus:ring-green-300 border-green-500
}

.btn-blue {
  @apply transition text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-300 border-blue-500
}

.btn-indigo{
  @apply transition text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus:ring-indigo-300 border-indigo-500
}

.btn-purple{
  @apply transition text-white bg-purple-500 hover:bg-purple-600 active:bg-purple-700 focus:ring-purple-300 border-purple-500
}

.btn-pink{
  @apply transition text-white bg-pink-500 hover:bg-pink-600 active:bg-pink-700 focus:ring-pink-300 border-pink-500
}

.btn-outline-white{
  @apply transition text-white border-white hover:bg-gray-500 active:bg-black focus:ring-gray-300
}

.btn-outline-black{
  @apply transition text-black border-black hover:bg-gray-300 active:bg-white focus:ring-gray-300
}

.btn-outline-gray {
  @apply transition text-gray-600 border-gray-600 hover:text-white hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300
}

.btn-outline-red {
  @apply transition text-red-600 border-red-600 hover:text-white hover:bg-red-600 active:bg-red-700 focus:ring-red-300
}

.btn-outline-yellow {
  @apply transition text-yellow-600 border-yellow-600 hover:text-white hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-300
}

.btn-outline-green {
  @apply transition text-green-600 border-green-600 hover:text-white hover:bg-green-600 active:bg-green-700 focus:ring-green-300
}

.btn-outline-blue {
  @apply transition text-blue-600 border-blue-600 hover:text-white hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-300
}

.btn-outline-indigo {
  @apply transition text-indigo-600 border-indigo-600 hover:text-white hover:bg-indigo-600 active:bg-indigo-700 focus:ring-indigo-300
}

.btn-outline-purple {
  @apply transition text-purple-600 border-purple-600 hover:text-white hover:bg-purple-600 active:bg-purple-700 focus:ring-purple-300
}

.btn-outline-pink {
  @apply transition text-pink-600 border-pink-600 hover:text-white hover:bg-pink-600 active:bg-pink-700 focus:ring-pink-300
}

//Bootstrap Style
.btn-primary {
  @apply bg-blue-500 text-blue-100 hover:bg-blue-600 duration-300 border-blue-500;
}

.btn-secondary {
  @apply bg-gray-600 text-gray-100 hover:bg-gray-700 duration-300 border-gray-600;
}

.btn-success {
  @apply bg-green-700 text-white hover:bg-green-800 duration-300 border-green-700;
}

.btn-danger {
  @apply bg-red-600 text-red-100 hover:bg-red-700 duration-300 border-red-600;
}

.btn-warning {
  @apply bg-yellow-500 hover:bg-yellow-600 duration-300 border-yellow-500;
}

.btn-info {
  @apply bg-green-300 hover:bg-green-400 duration-300 border-green-300;
}

.btn-light {
  @apply bg-gray-200 hover:bg-gray-300 duration-300 border-gray-200;
}

.btn-dark {
  @apply bg-gray-900 text-gray-100 border-gray-900;
}

.btn-link {
  @apply underline text-blue-500 hover:text-blue-600 duration-300 border-0;
}

.btn-outline-primary {
  @apply border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-blue-100 duration-300;
}

.btn-outline-secondary {
  @apply border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-gray-100 duration-300;
}

.btn-outline-success {
  @apply border-green-700 text-green-700 hover:bg-green-700 hover:text-green-100 duration-300;
}

.btn-outline-danger {
  @apply border-red-600 text-red-600 hover:bg-red-600 hover:text-red-100 duration-300;
}

.btn-outline-warning {
  @apply border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-yellow-100 duration-300;
}

.btn-outline-info {
  @apply border-green-300 text-green-300 hover:bg-green-300 hover:text-green-100 duration-300;
}

.btn-outline-light {
  @apply border-gray-200 text-gray-200 hover:bg-gray-200 hover:text-gray-900 duration-300;
}

.btn-outline-dark {
  @apply border-gray-900 text-gray-900 hover:bg-gray-900 hover:text-gray-100 duration-300;
}

//Detail form
.custom-input {
  @apply appearance-none block w-full bg-gray-50 disabled:bg-gray-200;
  @apply py-3 px-4 mt-1 border border-gray-300 rounded;
  @apply leading-tight;
  @apply text-gray-700 disabled:text-gray-500 text-[14px] md:text-[16px];
  // @apply focus:outline-none focus:bg-white focus:border-blue-100;

  &.error {
    @apply border-red-500 mb-2
  }

  &.medium {
    @apply px-3 py-2 text-sm;
  }

  &.small {
    @apply px-2 py-2 text-xs;
  }
}

.custom-multi-select {
  @apply mt-3 ; // appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4
  //leading-tight focus:outline-none focus:bg-white focus:border-blue-100 mt-1 text-[14px] md:text-[16px];

  &.error {
    @apply border-red-500 mb-2
  }
}

ng-multiselect-dropdown {
  .multiselect-dropdown {
    @apply mt-1 leading-tight;

    .dropdown-btn {
      @apply text-[14px] md:text-[16px] text-gray-700 bg-gray-50;
      padding: 0.6rem 1rem !important;
      border-color: rgb(209 213 219)!important;
      border-radius: 0.25rem!important;

      .selected-item {
        max-width: 100%!important;
        background: #3b82f6!important;
        border: none!important;
      }

      .dropdown-multiselect__caret:before {
        border-width: 8px 7px 0 !important;
      }
    }
  }

  .dropdown-list {
    .multiselect-item-checkbox input[type='checkbox'] + div {
      @apply text-[14px] md:text-[16px];
      color: rgb(55 65 81)!important;
    }

    .multiselect-item-checkbox input[type='checkbox']:focus + div:before,
    .multiselect-item-checkbox input[type='checkbox']:hover + div:before {
      border-color: #3b82f6;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div:before {
      color: #3b82f6!important;
      border: #3b82f6;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div:after {
      background-color: #3b82f6!important;
    }

    .multiselect-item-checkbox input[type='checkbox']:checked + div:before {
      //color: red;
      background: #3b82f6!important;
    }
  }
}

.custom-checkbox {
  @apply text-blue-500 w-8 h-8 mr-2 border border-gray-300 rounded;
  @apply cursor-pointer;
  @apply focus:ring-blue-400 focus:ring-opacity-50;

  span {
    @apply cursor-pointer;
  }

  &.disabled {
    @apply text-gray-500;
  }

  &.indeterminate {
    @apply text-gray-800;
  }

  &.no-label {
    @apply mr-0;
  }

  &.medium {
    @apply w-5 h-5;
  }

  &.small {
    @apply w-3 h-3;
  }
}

.custom-checkbox-text {
  @apply ml-1 cursor-pointer;

  &.bold {
    @apply font-bold;
  }
}

.custom-radio {
  @apply text-blue-500 w-8 h-8 mr-2 focus:ring-blue-400 focus:ring-opacity-50 border border-gray-300 rounded-full;

  &.disabled {
    @apply text-gray-500;
  }

  &.medium {
    @apply w-5 h-5;
  }

  &.small {
    @apply w-3 h-3;
  }
}

.error-text {
  @apply text-red-500 text-xs italic;
}

.link {
  @apply text-blue-700 hover:underline cursor-pointer;
}

.modal-body-details {
  min-width: 340px;
}

label {
  font-size: 12px;

  &.small {
    font-size: 10px;
  }
}

.my-tooltip {
  border-bottom: 1px dashed;
  text-decoration: none;

  span {
    display: none;
  }

  &:hover {
    cursor: help;
    position: relative;

    span {
      border: #666 2px dotted;
      padding: 5px 20px 5px 5px;
      display: block;
      z-index: 100;
      background: #e3e3e3;
      left: 0px;
      margin: 15px;
      width: 300px;
      position: absolute;
      top: 15px;
      text-decoration: none;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-container {
  @apply absolute origin-top-right z-10;
  @apply left-0 right-0 mt-1.5 py-1 px-2;
  @apply rounded-md shadow-lg border border-blue-500 text-gray-600;
  @apply bg-white  ;
  @apply focus:outline-none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    // left: 150px;
    left: 14px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;

    @apply border-b-blue-500;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    // left: 151px;
    left: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 6px solid transparent;

    @apply border-b-white;
  }
}

.loader-container {
  z-index: 0;
  position: relative;
  height: 100px;
  width: 100px;
}

@media (max-width: 768px) {
  .modal-body-details {
    min-width: 300px;
  }
}

@media (max-width: 370px) {
  .modal-body-details {
    min-width: 260px;
  }
}

